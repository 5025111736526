.home-page{
    /* margin-left: 100px; */

    .text-zone{
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }

    h1 {
        color: #ffffff;
        font-size: 35px;
        margin: 0;
        font-family: 'Helvetica Neue';

        &::before {
            content: '<h1>';
            font-family: 'Courier New', Courier, monospace;
            color: #d9ff02;
            font-size: 14px;
            position: absolute;
            margin-top: -40px;
            margin-left: 15px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }

        &::after {
            content: '<h1/>';
            font-family: 'Courier New', Courier, monospace;
            color: #d9ff02;
            font-size: 14px;
            position: absolute;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }
        
    }
    img {
        width: 50px;
        margin-left: 10px;
        margin-right: 5px;
        margin-bottom: -5px;
        opacity: 0;
        height: auto;
        animation: rotateIn 1s linear both;
        animation-delay: 1.4s;
    }
}

h2 {
    color: #d9ff02;
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Helvetica Neue';
    letter-spacing: 1px;
    animation: fadeIn 1s 1.8s backwards;
}

.flat-button {
    color: #d9ff02;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 2px;
    font-family: 'Helvetica Neue';
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #d9ff02;
    margin-top: 25px;
    float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;

    &:hover{
        background:#d9ff02;
        color: #333333;

    font-size: 16px;
    font-weight: 400;
    letter-spacing: 2px;
    font-family: 'Helvetica Neue';
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #d9ff02;
    margin-top: 25px;
    float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;
    }
}

@media screen and (max-width: 1200px) {
    .tags, 
    .home-page h1::before,
    .home-page h1::after {
        display:none;
    }

    .home-page {
        .text-zone{
            position: initial;
            width: 100%;
            transform: none;
            padding: 10px;
            box-sizing: border-box;
        }

        .flat-button {
            float: none;
            display: block;
            margin: 20px auto 0 auto;
            width: 125px;

        }

        .logo-container {
            position: relative;
            width: 100px;
            height: auto;
            top: 50px;
            right: 0;
            box-sizing: border-box;
            margin: auto;
            left: 0;

            svg {
                position: absolute;
                top: auto;
                right: auto;
                bottom: auto;
                left: 0;
                margin: auto;
            }
        }
    }
}