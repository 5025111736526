a {
    color: #ffffff;
    font-size: 20px;
    margin: 0;
    font-family: 'Helvetica Neue';
    text-decoration: none;
    letter-spacing: 1px;
    font-weight: 400;

}

a:hover {
    color: #d9ff02;
    font-size: 20px;
    margin: 0;
    font-family: 'Helvetica Neue';
    text-decoration: none;
    letter-spacing: 1px;
}