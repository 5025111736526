.portfolio-page {
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: auto;

    h1.page-title {
        margin-left: 0px;
        margin-top: 100px; 
    }

    .images-container {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        padding-bottom: 100px;
    }

    .image-box {
        position: relative;
        flex: 1 1 350%;
        min-height: 300px;
        overflow: hidden;
        max-width: calc(33% - 20px);
        
        .portfolio-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        
        }

        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 20px;
            transition: cubic-bezier(0.645, 0.045, 0.355, 1);
            background-color: #08003b50;
            bottom: -65px;
        }

        .title {
            margin-bottom: 0;
            margin-top: 0;
            color: #d9ff02;
            font-size:18px;
            font-weight: 500;
            line-height: 24px;
        }

        .description {
            font-size: 14px;
            margin-bottom: 5px;
            color: #d9ff02;;
            font-weight: 500;
        }

        .portfolio-url {
            font-size: 14px;
            margin-bottom: 5px;
            color: #d9ff02;;
            font-weight: 800;
            text-decoration: underline;

        }
        .portfolio-url:hover{
            content: '';
            text-decoration: line-through;
        }

        &:after {
            content: '';
            background-color: #08003bfa;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            transition: cubic-bezier(0.645, 0.045, 0.355, 1);
            z-index: 2;
            opacity: 0;
        }

        &:hover:after {
            opacity: 1;
        }

        &:hover .content {
            bottom: 0;
        }
    }

   
}

@media screen and (max-width: 1200px) {
    .portfolio-page{
        padding-left: 20px !important;
        padding-right: 0px !important;
       width: 100%;
    }

    .container .portfolio-page {
       width: 100%;
       padding: 10px;
       box-sizing: border-box;

       padding-left: 10px;
       padding-right: 10px;
       width: calc(100% - 150px);

       h1 .page-title {
           margin-left: 10px;
           margin-top: 10px;
       }

       .images-container {
        .image-box {
            height:50px;
 
        }
       }


       

      

   }

   .image-box {
    flex: 1 1 50%;
    min-height: 200px;
    max-width: calc(45% - 10px) !important;
    
    .portfolio-image {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        object-fit: cover;
    
    }

    .content {
        position: absolute;
        width: 100%;
        z-index: 3;
        padding: 10px 20px;
        transition: cubic-bezier(0.645, 0.045, 0.355, 1);
        background-color: #08003b50;
        bottom: -65px;
    }
    
}
    
  }

  