.page{
    width: 100%;
    height: 100%;
    position: absolute;
}

.top-tags{
    bottom: auto;
    top: 35px;
}

.tags{
    color: #d9ff02;
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 14px;
    font-family: monospace;

}

.bottom-tag-html {
    margin-left: -20px;
}

.container{
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}



.container.about-page,
.container.portfolio-page {
    

        h1 {
            font-size: 53px;
            font-family: 'Helvetica Neue';
            color: #d9ff02;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom:  40px;
            left: 10px;

            &::before{
                content: '<h1>';
                font-family: monospace;
                font-size: 14px;
                position: absolute;
                margin-top: -10px;
                left: -10px;
                opacity: 0.6;
                list-style: 18px;
            }

            &::after {
                content: '</h1>';
                font-family: monospace;
                font-size: 14px;
                position: absolute;
                left: -30px;
                opacity: 0.6;
                bottom: -20px;
                margin-left: 20px;
                opacity: 0.6;
            }
        }
        p {
            font-size: 16px;
            color: #fff;
            letter-spacing: 2px;
            font-family: 'Helvetica Neue';
            font-weight: 300px;
            min-width: fit-content;
            animation: pulse 1s;

            &:nth-of-type(1) {
                animation-delay: 1.1s;
            }
            &:nth-of-type(2) {
                animation-delay: 1.2s;
            }
            &:nth-of-type(3) {
                animation-delay: 1.3s;
            }

        }
        .text-zone {
            position: absolute;
            left: 10%;
            top: 50%;
            transform: translateY(-50%);
            width: 35%;
            vertical-align: middle;
            display: table-cell;
            max-height: 90%;
    }

    .text-animate-hover{
        &:hover {
            color: #fff
        }
    }
}

@media screen and (max-width: 1200px) {
    .page {
        position: initial;
    }

    .container {
        position: initial;
        height: auto;
        min-height: auto;

        &.about-page,
        &.contact-page {
            .text-zone {
                position: initial;
                transform: none;
                width: 100%;
                display: block;
                padding: 20px;
                box-sizing: border-box;
            }
            &.portfolio-page {
                position: initial;
                transform: none;
                width: 100%;
                display: block;
                padding: 20px;
                box-sizing: border-box;
            }

        }
    }
}